import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import headerImg from '../images/version2/header-img.png'
import mokeImg from '../images/version2/moke-img.png'
import experienceImgLeft from '../images/version2/experience-img-left.png'
import experienceImgRight from '../images/version2/experience-img-right.png'
import YellowMoke from '../images/version2/yellow-moke.png'
import electricIcon from '../images/version2/electric-icon.svg'
import camraIcon from '../images/version2/camra-icon.svg'
import headlightIcon from '../images/version2/headlight-icon.svg'
import bluetoothIcon from '../images/version2/bluetooth-icon.svg'
import seatsIcon from '../images/version2/seats-icon.svg'
import licenseIcon from '../images/version2/license-icon.svg'
import speedometerIcon from '../images/version2/speedometer.svg'
import calenderIcon from '../images/version2/calender-icon.svg'
import savannah from '../images/version2/savannah.png'
// import hamptons from '../images/version2/the-hamptons.png'
import optionaldeliveryIcon from '../images/version2/optional-delivery-icon.svg'
import mokeIcon from '../images/version2/moke-icon.svg'
import miamiBeach from "../images/version2/WeMokeRentals-miamibeach-300x180.png";
import delayBeach from "../images/version2/WeMokeRentals-delraybeach-300x180.png";
import bocaRaton from "../images/version2/WeMokeRentals-bocaraton-300x180.png";
import westPalm from "../images/version2/WeMokeRentals-westpalmbeach-300x180.png";
import savannah2 from "../images/version2/WeMokeRentals-Savannah-300x180.png";

import Honolulu from "../images/version2/WeMokeRentals-honolulu-300x180.png";
import Lauderdale from "../images/version2/Fort-lauderdale-300x200.jpg";
import Grove from "../images/version2/Coconut-Grove-Florida-300x200.jpg";
import Clearwater from "../images/version2/Wemoke-Wyndham-Clearwater-300x169.jpg";
import IsleOfPalms from "../images/version2/Isle-of-Palms-300x169.jpg";


  

import nashville from '../images/version2/location-nashville.png'
import locationLeft from '../images/version2/location-left-img.png'
import locationRight from '../images/version2/location-right-img.png'
// import footerOne from '../images/version2/footer-img-one.png'
// import footerTwo from '../images/version2/footer-img-two.png'
// import footerThree from '../images/version2/footer-img-three.png'
// import footerFour from '../images/version2/footer-img-four.png'
// import footerFive from "../images/Blue_Moke.jpg";

import SliderOne from "../../src/images/slider-one.png";
import SliderTwo from "../../src/images/slider-two.png";
import SliderThree from "../../src/images/slider-three.png";
import SliderFour from "../../src/images/slider-four.png";
import SliderFive from "../../src/images/slider-five.png";
import Blue_Moke from "../../src/images/Blue_Moke.jpg";




// import Headerv2 from '../component/HeaderV2';
// import Footerv2 from '../component/FooterV2';

var testimonials = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        slidesToShow: 1,
      }

    },
  ]
};

const Home2 = () => {
  const [IGFEED, setIGFEED] = useState([
    {
      caption: "",
      id: "",
      media_type: "IMAGE",
      media_url: SliderOne,
      permalink: ""
    },
    {
      caption: "",
      id: "",
      media_type: "IMAGE",
      media_url: Blue_Moke,
      permalink: ""
    },
    {
      caption: "",
      id: "",
      media_type: "IMAGE",
      media_url: SliderTwo,
      permalink: ""
    },
    {
      caption: "",
      id: "",
      media_type: "IMAGE",
      media_url: SliderThree,
      permalink: ""
    },
    {
      caption: "",
      id: "",
      media_type: "IMAGE",
      media_url: SliderFour,
      permalink: ""
    },
    {
      caption: "",
      id: "",
      media_type: "IMAGE",
      media_url: SliderFive,
      permalink: ""
    },
  ])
  const mockimages = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
    ],
  };

  // const token = process.env.REACT_APP_INSTAGRAM_AUTH_TOKEN
  // var url = process.env.REACT_APP_INSTAGRAM_FEED_API + token;
  // const fetchIg = async () => {
  //   try {
  //     const { data } = await axios.get(url)
  //     if (data?.data?.length > 0) {
  //       let arr = [...data?.data?.filter(e => e?.media_type === "IMAGE" && e), ...IGFEED];
  //       arr.length = 6
  //       setIGFEED(data?.data?.length < 6 ? arr : data?.data)
  //     }
  //   }
  //   catch (error) { console.log(error) }
  // }
  // useEffect(() => {
  //   fetchIg()
  //   // eslint-disable-next-line
  // }, [])
  return (
    <>
      {/* <Headerv2 /> */}
      <section className="sunshine-ride-wrapper">
        <div className="container">
          <div className="sunshine-img-wrap">
            <img src={headerImg} />
          </div>
          <div className="sunshine-text">
            <h3>Sunshine Rides</h3>
            <p>Rent a moke</p>
            <button onClick={window["openChat"]} className="book-now">
              Book now
            </button>
          </div>
        </div>
      </section>
      <section className="moke-section">
        <div className="container">
          <div className="row moke-wrapper">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="moke-text">
                <h4>What Is A Moke?</h4>
                <p>
                  We’re a street legal, open-air, electric cruiser meant for
                  local driving.
                </p>
                <Link to="/history" className="learn-more">
                  Learn more
                </Link>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <div className="moke-image">
                <img src={mokeImg} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wemoke-exper-wrap-v2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Exper-text">
                <h4>The WeMoke Experience</h4>
                <p>
                  Our easy-breezy electric vehicle seats 4 people, and features
                  a Bluetooth radio, backup camera and headlights. Classified as
                  a low-speed vehicle (LSV), it reaches a top speed of 25 mph,
                  allowing for use on streets with a posted speed limit of 35
                  mph or less. Perfect for going to the beach, sightseeing,
                  family excursions, bachelor/bachelorette parties, social media
                  photo shoots, weddings, branding activations and more!
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 exper-img-space">
              <img src={experienceImgLeft} />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <img src={experienceImgRight} />
            </div>
          </div>
        </div>
      </section>
      <section className="moke-rolls">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center btn--margin-bottom">
              <button onClick={window["openChat"]} className="book-now">
                Book now
              </button>
            </div>
            <div className="col-md-12">
              <div className="moke-roll-wrap">
                <div className="moke-roll-image">
                  <img src={YellowMoke} />
                </div>
                <div className="moke-roll-text">
                  <h4>How To Moke</h4>
                  <p>
                    The Moke rolls slow and low with a max speed of 25 mph,
                    perfect for fun excursions in sunny beach towns and resort
                    areas. You'll stay on roads 35 mph or less and enjoy local
                    adventures. Mokes can be rented by the hour, day, week or
                    month. The key is located in a lockbox and renters will
                    receive the code via SMS for easy access right before their
                    rental starts. Drivers must be 21 years old and possess a
                    valid U.S. driver’s license.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features-section-v2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="features-text">
                <h4>Key features</h4>
                <p>Your comfort and safety are our priority!</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ul className="features-listing">
                <li>
                  <img src={electricIcon} />
                  <h6>Fully electric</h6>
                </li>
                <li>
                  <img src={camraIcon} />
                  <h6>Backup Camera</h6>
                </li>
                <li>
                  <img src={headlightIcon} />
                  <h6>Headlights for nighttime rides</h6>
                </li>
                <li>
                  <img src={bluetoothIcon} />
                  <h6>Bluetooth radio</h6>
                </li>
                <li>
                  <img src={seatsIcon} />
                  <h6>Seats 4 people comfortably</h6>
                </li>
                <li>
                  <img src={licenseIcon} />
                  <h6>
                    Must be 21 years old to drive with a valid U.S. driver’s
                    license.
                  </h6>
                </li>
                <li>
                  <img src={speedometerIcon} />
                  <h6>
                    Street legal on all roads 35 MPH or less. Meant for local
                    driving & a top speed of 25 mph.
                  </h6>
                </li>
                <li>
                  <img src={calenderIcon} />
                  <h6>You can rent a Moke by the hour, day, week or month</h6>
                </li>
                <li>
                  <img src={optionaldeliveryIcon} />
                  <h6>Optional delivery available - Please inquire</h6>
                </li>
                <li>
                  <img src={mokeIcon} />
                  <h6>Can be custom wrapped for events/branding.</h6>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="wemoke-location">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="location-text">
                <h4>WeMoke Locations</h4>
                <p>
                  We have partnered with luxurious hotels & resorts across the
                  country
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ul className="cities-listing">
                <li>
                  <a href="/locations/miami-beach/">
                    <div className="img-box">
                      <img src={miamiBeach} />
                    </div>
                    <p>Miami Beach </p>
                  </a>
                </li>
                {/* <li>
                  <a href="/locations/delray-beach/">
                    <div className="img-box">
                      <img src={delayBeach} />
                    </div>
                    <p>Delray Beach </p>
                  </a>
                </li> */}
                <li>
                  <a href="/locations/boca-raton/">
                    <div className="img-box">
                      <img src={bocaRaton} />
                    </div>
                    <p>Boca Raton </p>
                  </a>
                </li>
                <li>
                  <a href="/locations/palm-beach/">
                    <div className="img-box">
                      <img src={westPalm} />
                    </div>
                    <p>West Palm Beach </p>
                  </a>
                </li>
                <li>
                  <a href="/locations/savannah/">
                    <div className="img-box">
                      <img src={savannah2} />
                    </div>
                    <p>Savannah</p>
                  </a>
                </li>
                <li>
                  <a href="/locations/nashville">
                    <div className="img-box">
                      <img src={nashville} />
                    </div>
                    <p>Nashville</p>
                  </a>
                </li>
                <li>
                  <a href="/locations/honolulu">
                    <div className="img-box">
                      <img src={Honolulu} />
                    </div>
                    <p>Honolulu</p>
                  </a>
                </li>
                <li>
                  <a href="/locations/fort-lauderdale">
                    <div className="img-box">
                      <img src={Lauderdale} />
                    </div>
                    <p>Fort Lauderdale</p>
                  </a>
                </li>
                <li>
                  <a href="/locations/coconut-grove">
                    <div className="img-box">
                      <img src={Grove} />
                    </div>
                    <p>Coconut Grove</p>
                  </a>
                </li>
                <li>
                  <a href="/locations/clearwater">
                    <div className="img-box">
                      <img src={Clearwater} />
                    </div>
                    <p>Clearwater beach</p>
                  </a>
                </li>
                <li>
                  <a href="/locations/isle-of-palms">
                    <div className="img-box">
                      <img src={IsleOfPalms} />
                    </div>
                    <p>Isle of Palms</p>
                  </a>
                </li>
              </ul>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 cities-space">
                  <img src={locationLeft} />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <img src={locationRight} />
                </div>
              </div>
              <div className="special-event-wrap">
                <div className="special-event-text">
                  <h4>Special Occasions & Events</h4>
                  <h6>
                    Rent a Moke for your wedding, special event or corporate
                    branding
                  </h6>
                </div>
                <div className="special-event-btn">
                  <Link to="/contact">Special Events </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonials-section version-two">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4 className="testimonials-heading">
                What Our Customers are Saying
              </h4>
              <div className="testimonials-bg">
                <Slider {...testimonials}>
                  <div>
                    <p>
                      ”The process to book a Moke was seamless and the staff was
                      so friendly! I loved coming back to a spotless Moke and
                      can't wait to use it again!”
                    </p>
                    <h4>Jocelyn S.</h4>
                  </div>
                  <div>
                    <p>
                      ”I spent a day on Fisher Island and bopped around in a
                      Moke, going to the Tiki Bar and cruising around the
                      island. So much fun!”
                    </p>
                    <h4>Emily W.</h4>
                  </div>
                  <div>
                    <p>
                      ”Cute ride to use around town. Definitely recommend to get
                      around or just for a fun little experience. My daughter
                      loved it!”
                    </p>
                    <h4>Arlene</h4>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* testimonials section open */}
      <section className="pictures-section mockimages">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="pictures-bg">
                <Slider {...mockimages}>
                  {IGFEED?.length > 0 &&
                    IGFEED?.map((f, i) => {
                      if (f?.media_type === "IMAGE") {
                        return (
                          <div key={i} className="imgwrap">
                            <img
                              src={f?.media_url}
                              // onClick={() => f?.permalink && window.open(f?.permalink, '_blank')}
                              alt="instagram-feed"
                            />
                          </div>
                        );
                      }
                    })}
                  {/* <div>
                    <div className="imgwrap">
                      <img src={SliderOne} alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="imgwrap">
                      <img src={Blue_Moke} alt="Blue Moke" />
                    </div>
                  </div>
                  <div>
                    <div className="imgwrap">
                      <img src={SliderTwo} alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="imgwrap">
                      <img src={SliderThree} alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="imgwrap">
                      <img src={SliderFour} alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="imgwrap">
                      <img src={SliderFive} alt="" />
                    </div>
                  </div> */}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* testimonials section close */}
    </>
  );
}
export default Home2



// function ga(action, category, label) {
//   ReactGA.event({
//     category,
//     action,
//     label
//   });
// }